const env = {
  CLIENT_ID:
    '688928621123-2vd96hff89aur35c7n200i6dvr6vkrji.apps.googleusercontent.com',
  API_KEY: 'AIzaSyCws6S6MtVP8R84ZXeOwdF-9BeC2DS3sGk',
  spreadsheetId: '1aRRQb-N1-Bg-pIgrBRV6W0ZEAUKpVjPvOWcbczYbEao',

  DISCOVERY_DOCS: ['https://sheets.googleapis.com/$discovery/rest?version=v4'],
  SCOPES: 'https://www.googleapis.com/auth/spreadsheets',

  MAP_API_KEY: 'AIzaSyD_ly-B7d2kwr0zoGlCJSu4yPJuFrNbPsk',
};

export default env;
